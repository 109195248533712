<template>
  <div class="container-fluid">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Manage Bookings</h1>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Booking Details</h6>
          </div>
          <div class="card-body">
            <form class="bookingData">
              <fieldset disabled>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>First Name</label>
                    <input type="text" class="form-control" :placeholder="booking.firstName">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Last Name</label>
                    <input type="text" class="form-control" :placeholder="booking.lastName">
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>Email</label>
                    <input type="text" class="form-control" :placeholder="booking.email">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Phone Number</label>
                    <input type="text" class="form-control" :placeholder="booking.phoneNumber">
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>Competition</label>
                    <input type="text" class="form-control" :placeholder="booking.competition.eventName">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Dance Level</label>
                    <input type="text" class="form-control" :placeholder="booking.danceLevel">
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4" v-if="booking">
                    <label>Stylist Preference</label>
                    <input type="text" class="form-control" :placeholder="booking.stylistPreference">
                  </div>
                  <div class="col-lg-4" v-if="booking">
                    <label>Hair the night before?</label>
                    <input type="text" class="form-control" :placeholder="booking.hairAtNight">
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-8" v-if="booking">
                    <label>Message</label>
                    <textarea rows="4" class="form-control" :placeholder="booking.message"></textarea>
                  </div>
                </div>
              </fieldset>
            </form>
            <br />
            <hr>
            <h3>Assign Booking</h3>
            <form @submit.prevent="editBooking" v-if="booking">
              <div v-for="(day, index) in expandedDayDetails" :key="index" class="day-slot">
                <h5>{{ formatDate(day.date) }}</h5>
                <h6>Service: {{ day.serviceTitle }}</h6>

                <!-- Disabled inputs for Additional Services and Dance Styles -->
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Additional Services</label>
                      <textarea
                        class="form-control"
                        :value="formatAdditionalServices(day.additionalServices)"
                        rows="3"
                        readonly
                      ></textarea>
                      <input type="hidden" v-model="day.additionalServices" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Dance Styles</label>
                      <textarea
                        class="form-control"
                        :value="day.danceStyle?.join(',\n') || 'None'"
                        rows="3"
                        readonly
                      ></textarea>
                      <input type="hidden" v-model="day.danceStyle" />
                    </div>
                  </div>
                </div>

                <!-- Assign User Section -->
                <div class="row">
                  <div class="col-lg-6">
                    <label>Assign User</label>
                    <select 
                      v-model="day.assignedUser" 
                      class="form-control"
                    >
                      <option 
                        v-for="user in users" 
                        :key="user._id" 
                        :value="user"
                      >
                        {{ user.firstName }} {{ user.lastName }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- Start/End Date and Time -->
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="startDate">Start Date</label>
                      <input type="date" v-model="day.startDate" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="startTime">Start Time</label>
                      <input type="time" v-model="day.startTime" class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="endDate">End Date</label>
                      <input type="date" v-model="day.endDate" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="endTime">End Time</label>
                      <input type="time" v-model="day.endTime" class="form-control" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Dance Start Details and Additional Notes -->
              <div class="day-slot">
                <h5>Dancers Scheduled Dance Start Date/Time</h5>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="endDate">Dance Start Date</label>
                      <input type="date" v-model="booking.danceStartDate" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="endTime">Dance Start Time</label>
                      <input type="time" v-model="booking.danceStartTime" class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="additionalNotes">Additional Notes</label>
                      <textarea v-model="booking.additionalNotes" class="form-control"></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Save and Back Buttons -->
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <router-link class="btn btn-primary btn-icon-split" to="/admin/calendar_bookings">
                      <span class="icon text-white-50">
                        <i class="fas fa-chevron-left"></i>
                      </span>
                      <span class="text">Back to Manage Bookings</span>
                    </router-link>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group text-right">
                    <button type="submit" class="btn btn-success btn-icon-split">
                      <span class="icon text-white-50">
                        <i class="fas fa-check"></i>
                      </span>
                      <span class="text">Save</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div class="row">
              <div class="col-lg-6">
                <button @click="deleteBooking" type="button" class="btn btn-danger btn-icon-split">
                  <span class="icon text-white-50">
                    <i class="fas fa-trash"></i>
                  </span>
                  <span class="text">Delete Booking</span>
                </button>
              </div>
              <div class="col-lg-6 text-right">
                <button @click="sendBookingEmail" type="button" class="btn btn-info btn-icon-split">
                  <span class="icon text-white-50">
                    <i class="fas fa-envelope"></i>
                  </span>
                  <span class="text">Send Booking Email</span>
                </button>
                <br />
                <span v-if="contentEmailSent" class="badge badge-pill badge-success" style="font-size: 0.8rem;">Email Sent</span>
                <span v-if="contentEmailError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Email Sending Error</span>
                <span v-if="contentEmailRequiredDataError" class="badge badge-pill badge-danger" style="font-size: 0.8rem;">Email Not Sent. Please assign a user and/or date(s) and time(s)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { fetchBooking, updateBookingDetails, deleteBookingRequest, sendBookingEmail } from '@/utils/booking';
import { fetchUsers } from '@/utils/users';

export default {
  name: 'AdminEditBooking',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      users: [],
      booking: null,
      expandedDayDetails: [],
      contentEmailSent: false,
      contentEmailError: false,
      contentEmailRequiredDataError: false,
    };
  },
  async created() {
    await this.loadUsers();
    await this.loadBooking();
  },
  methods: {
    async loadBooking() {
      try {
        const booking = await fetchBooking(this.id);
        this.booking = booking;

        // Maintain a record of processed day-schedule combinations to avoid duplication
        const existingDaySchedules = new Set();

        // Flatten schedules within each dayDetail into expandedDayDetails
        this.expandedDayDetails = booking.dayDetails.flatMap((day) => {
          // If schedules are empty, dynamically generate them based on the service
          if (!day.schedules || day.schedules.length === 0) {
            const services = day.services.title.split('&').map((service) => service.trim()); // Split services by '&'

            // Create default schedules for each service
            return services.map((serviceTitle) => {
              // Create a unique key for the date and service title to prevent duplication
              const key = `${day.date}-${serviceTitle}`;
              if (existingDaySchedules.has(key)) return null; // Skip duplicates

              existingDaySchedules.add(key);

              return {
                date: day.date,
                service: day.services, // Full service object for reference
                serviceTitle: serviceTitle, // Sub-service title
                additionalServices: day.additionalServices || [], // Additional services
                danceStyle: day.danceStyle || [], // Dance styles
                assignedUser: null, // No user assigned initially
                startDate: '', // Start date
                startTime: '', // Start time
                endDate: '', // End date
                endTime: '', // End time
              };
            });
          }

          // Process existing schedules
          return day.schedules.map((schedule) => {
            const key = `${day.date}-${schedule.service}`;
            if (existingDaySchedules.has(key)) return null; // Skip duplicates

            existingDaySchedules.add(key);

            return {
              date: day.date,
              service: day.services, // Full service object for reference
              serviceTitle: schedule.service || '', // Sub-service title
              additionalServices: day.additionalServices || [], // Additional services
              danceStyle: day.danceStyle || [], // Dance styles
              assignedUser: this.users.find((user) => user._id === schedule.assignedUser?._id) || null, // Assigned user
              startDate: schedule.startDate || '', // Start date
              startTime: schedule.startTime || '', // Start time
              endDate: schedule.endDate || '', // End date
              endTime: schedule.endTime || '', // End time
            };
          });
        }).filter(Boolean); // Remove null entries

      } catch (error) {
        console.error('Error fetching booking:', error);
      }
    },
    async loadUsers() {
      try {
        this.users = await fetchUsers(false);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    formatDate(dateString) {
      return moment(dateString).format('dddd, MMMM Do YYYY');
    },
    formatAdditionalServices(services) {
      // Extract titles from additional services or return 'None'
      return services?.map((service) => service.title).join(',\n') || 'None';
    },
    async editBooking() {
      try {
        // Construct the updated booking object, allowing partial updates
        const updatedBooking = {
          ...this.booking,
          dayDetails: this.expandedDayDetails.reduce((acc, day) => {
            // Find or create the corresponding dayDetail in acc
            let dayDetail = acc.find(
              (d) => d.date === day.date && d.services._id === day.service._id
            );

            if (!dayDetail) {
              dayDetail = {
                date: day.date,
                services: day.service, // Reference to the service object
                additionalServices: day.additionalServices, // Preserve additional services
                danceStyle: day.danceStyle, // Preserve dance styles
                schedules: [],
              };
              acc.push(dayDetail);
            }

            // Add schedule to the dayDetail
            dayDetail.schedules.push({
              service: day.serviceTitle, // Sub-service title
              assignedUser: day.assignedUser?._id || null, // Assign user _id or null
              startDate: day.startDate || '', // Start date
              startTime: day.startTime || '', // Start time
              endDate: day.endDate || '', // End date
              endTime: day.endTime || '', // End time
            });

            return acc;
          }, []),
        };

        const response = await updateBookingDetails(updatedBooking);
        if (response.update) {
          alert('Booking updated successfully');
        }
      } catch (error) {
        console.error('Error updating booking:', error);
      }
    },
    async deleteBooking() {
      if (confirm('Are you sure you want to delete this booking?')) {
        try {
          const response = await deleteBookingRequest(this.id);
          if (response.deleted) {
            alert('Booking deleted successfully');
            this.$router.push('/admin/calendar_bookings');
          }
        } catch (error) {
          console.error('Error deleting booking:', error);
        }
      }
    },
    async sendBookingEmail() {
      if (!this.booking) return;

      const allDatesFilled = this.expandedDayDetails.every(day => day.startDate && day.startTime && day.endDate && day.endTime && day.assignedUser);
      if (!allDatesFilled) {
        this.contentEmailRequiredDataError = true;
        setTimeout(() => (this.contentEmailRequiredDataError = false), 5000);
        return;
      }

      if (confirm('Are you sure you want to send an email to the customer?')) {
        try {
          const response = await sendBookingEmail(this.id);
          if (response.sent) {
            this.contentEmailSent = true;
            setTimeout(() => (this.contentEmailSent = false), 5000);
          } else {
            this.contentEmailError = true;
            setTimeout(() => (this.contentEmailError = false), 5000);
          }
        } catch (error) {
          console.error('Error sending email:', error);
          this.contentEmailError = true;
          setTimeout(() => (this.contentEmailError = false), 5000);
        }
      }
    },
  },
};
</script>

<style scoped>
.bookingData .row+.row {
  margin-top: 1rem;
}
.day-slot {
  margin-top: 1.5rem;
}
</style>