<template>
  <div class="container-fluid">
    <div class="d-sm-flex align-items-center justify-content-center mb-2 text-center">
      <h1 class="display-4" v-html="calendarYearRef"></h1>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card shadow mb-4">
          <div class="card-body">
            <div class="d-flex flex-wrap justify-content-center justify-content-xl-around m-2">
              <div class="btn-group btn-group-md m-2" role="group" aria-label="View Selection">
                <button type="button" :class="buttonDayClass" @click="showDay">Day</button>
                <button type="button" :class="buttonWeekClass" @click="showWeek">Week</button>
                <button type="button" :class="buttonMonthClass" @click="showMonth">Month</button>
              </div>
              <div class="btn-group btn-group-md m-2" role="group" aria-label="User Selection">
                <button v-for="user in userListRef" :key="user._id" @click="toggleUserCalendar(user._id, $event)" class="btn btn-outline-primary active">{{ user.firstName }}</button>
                <button type="button" @click="toggleDanceCalendar($event)" class="btn btn-outline-primary active">Booked Dancers</button>
              </div>
              <div class="m-2" aria-label="Calendar Pagination">
                <div class="md-2 text-center"><span v-html="dateRangeRef"></span></div>
                <button type="button" class="btn btn-dark mr-3" @click="calendarPrevious">Previous</button>
                <button type="button" class="btn btn-dark ml-3" @click="calendarNext">Next</button>
              </div>
            </div>
            <main id="calendar" style="height: 800px;"></main>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <AdminEventModal :body-html="modalEventBodyRef">
    <template v-slot:header>
      {{ modalEventHeaderRef }}
    </template>
  </AdminEventModal>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Calendar from '@toast-ui/calendar';
import AdminEventModal from '@/components/admin/AdminEventModal.vue';
import moment from 'moment';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import { fetchUsers } from '@/utils/users';
import { fetchAssignedBookings } from '@/utils/booking';

const CALENDAR_COLORS = ['#2088bd','#6b34b7','#a90b03','#03bd9e','#1aa38cs'];

const DANCE_LEVEL = {
  "junior": "Junior",
  "youth": "Youth",
  "amateur": "Amateur",
  "pro-am": "Pro-Am",
  "professional": "Professional",
};

const DANCE_STYLE = {
  "latin": "Latin",
  "standard": "Standard",
  "smooth": "Smooth",
  "rhythm": "Rhythm",
  "exhibition": "Exhibition / Theater Arts",
  "night-club": "Night Club",
  "show-dance": "Show Dance",
  "Other": "Other",
};

// Reactive property to store the button class
const buttonDayClass = ref('btn btn-outline-secondary');
const buttonWeekClass = ref('btn btn-outline-secondary active');
const buttonMonthClass = ref('btn btn-outline-secondary');

var calendarObj = null;
var userList = null;
//var earliestHour = 24;
//var latestHour = 0;
var userListRef = ref([]);
var dateRangeRef = ref([]);
var calendarYearRef = ref([]);
var modalEventHeaderRef = ref([]);
var modalEventBodyRef = ref([]);

async function loadUsers() {
  
  userList = await fetchUsers(false);
  
  if(userList != null && userList.length > 0) {
    userListRef.value = userList;
  }
}

function toggleUserCalendar(calendarId, event) {
  
  if(userList != null && userList.length > 0) {

    const buttonElement = event.target;
    
    if(buttonElement.classList.contains("active")) {
      calendarObj.setCalendarVisibility(calendarId, false);
      buttonElement.classList.remove('active');
    }
    else {
      calendarObj.setCalendarVisibility(calendarId, true);
      buttonElement.classList.add('active');
    }
  }
}

function toggleDanceCalendar(event) {
  
  const buttonElement = event.target;
  
  if (buttonElement.classList.contains("active")) {
    
    calendarObj.setCalendarVisibility('bookedDancers', false);
    buttonElement.classList.remove('active');
  } 
  else {
    calendarObj.setCalendarVisibility('bookedDancers', true);
    buttonElement.classList.add('active');
  }
}

async function createCalendar() {
  
  if(userList != null && userList.length > 0) {
    
    var usersCalendars = new Array(
    {
      id: "events",
      name: "Events",
      color: '#ffffff',
      borderColor: CALENDAR_COLORS[0],
      backgroundColor: CALENDAR_COLORS[0],
      dragBackgroundColor: CALENDAR_COLORS[0],
    },
    {
      id: "bookedDancers",
      name: "Booked Dancers",
      color: '#ffffff',
      borderColor: CALENDAR_COLORS[1],
      backgroundColor: CALENDAR_COLORS[1],
      dragBackgroundColor: CALENDAR_COLORS[1],
    });
    
    userList.forEach(function(item, index) {
      usersCalendars.push({
        id: item._id,
        name: item.firstName + "'s Calendar",
        color: '#ffffff',
        borderColor: CALENDAR_COLORS[index + 2],
        backgroundColor: CALENDAR_COLORS[index + 2],
        dragBackgroundColor: CALENDAR_COLORS[index + 2],
      });
    });

    calendarObj = new Calendar('#calendar', {
      usageStatistics: false,
      isReadOnly: true,
      useFormPopup: false,
      useDetailPopup: false,
      defaultView: 'week',
      week: {
        startDayOfWeek: 1,
        dayNames: ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
        taskView: false,
        eventView: true,
      },
      month: {
        visibleEventCount: 0,
      },
      calendars: usersCalendars,
      theme: {
        week: {
          dayGridLeft: {
            width: '4rem',
          },
          timeGridLeft: {
            width: '4rem',
          },
        },
        month: {
          gridCell: {
            headerHeight: 30,
            footerHeight: null,
          },
        },
      },
      template: {
        allday: function (event) {
          return '<span class="calendar-event-allday">' + event.title + '</span>';
        },
        alldayTitle: function () {
          return '<strong>All Day</strong>';
        },
        time: function (event) {
          if(calendarObj.getViewName() == "month") {
            return (
              '<span class="calendar-event-time"><strong>' + moment(event.start.toDate()).format("hh:mm A") + '</strong> ' + event.title + '</span>'
            );
          }
          else {
            return (
              '<span class="calendar-event-time"><strong>' + moment(event.start.toDate()).format("hh:mm A") + '</strong></span><br/>' +
              '<span class="calendar-event-time">' + event.title + '</span>'
            );
          }
        },
        weekGridFooterExceed: function (hiddenEvents) {
          return (
            '<span class="calendar-event-weekgrid-footer-exceed">+' + hiddenEvents + '</span>'
          );
        },
        monthGridHeaderExceed(hiddenEvents) {
          return `<button type="button" class="btn btn-sm btn-primary view-more-button"><span class="button-text">View More </span><span class="badge badge-pill badge-light">${hiddenEvents}</span></button>`;
        },
        collapseBtnTitle: function () {
          return 'Collapse';
        },
        timegridNowIndicatorLabel() {
          return 'current time';
        },
        monthGridHeader(model) {
          const date = parseInt(model.date.split('-')[2], 10);

          return `<span>${date}</span>`;
        },
      },
    });
    
    await loadCalendarEvents();
    
    // Registering custom events and event handlers
    calendarObj.on('clickDayName', (currentView) => {
      showDay();
      calendarObj.setDate(moment.utc(currentView.date).hour(12).toDate());
    });
    
    calendarObj.on('clickEvent', (event) => {
      showEventModal(event.event.title, event.event.body);
    });
    
    calendarObj.on('clickMoreEventsBtn', (moreEventsBtnInfo) => {
      moreEventsBtnInfo.target.style.width = "75%";
      moreEventsBtnInfo.target.style.height = "50%";
      moreEventsBtnInfo.target.style.top = "2%";
      moreEventsBtnInfo.target.style.left = "10%";
    });
    
    /*calendarObj.on('afterRenderEvent', () => {

    });*/
    
    //window.addEventListener("resize", handleWindowResize);
  }
}

/*function handleWindowResize(e) {

  if(calendarObj != null) {
    if (e.target.innerWidth <= 992) { 
      calendarObj.setCalendarVisibility('events', false);
    }
    else {
      calendarObj.setCalendarVisibility('events', true);
    }
  }
}*/

async function loadCalendarEvents() {
  const assignedBookings = await fetchAssignedBookings();
  const eventsList = [];

  // Add competition-related events
  assignedBookings.forEach((booking) => {
    const event = {
      id: `event_${booking.competition._id}`,
      calendarId: "events",
      title: booking.competition.eventName,
      start: booking.competition.eventStartDate,
      end: booking.competition.eventEndDate,
      body: `<strong>Address</strong><br/>${booking.competition.address}<br/>${booking.competition.city}<br/>${booking.competition.state}`,
      location: booking.competition.locationName,
      category: "allday",
      isAllday: true,
    };

    if (!eventsList.some((e) => e.id === event.id)) {
      eventsList.push(event);
    }
  });

  calendarObj.createEvents(eventsList);
  eventsList.length = 0; // Clear eventsList for booked events

  // Add booked events based on `dayDetails.schedules`
  assignedBookings.forEach((booking) => {
    booking.dayDetails.forEach((day) => {
      day.schedules.forEach((schedule, index) => {
        // Skip if start date or time is missing
        if (!schedule.startDate || !schedule.startTime || !schedule.endDate || !schedule.endTime || !schedule.assignedUser) return;

        // Build start and end datetime
        const startDateTime = `${schedule.startDate}T${schedule.startTime}`;
        const endDateTime = `${schedule.endDate}T${schedule.endTime}`;

        const assignedUser = schedule.assignedUser;
        const assignedUserName = `${assignedUser.firstName} ${assignedUser.lastName}`;

        const bodyText = `
          <strong>Event</strong>: ${booking.competition.eventName} <br/>
          <strong>Assigned User</strong>: ${assignedUserName} <br/>
          <strong>Service</strong>: ${schedule.service || "N/A"} <br/>
          <strong>Dance Level</strong>: ${DANCE_LEVEL[booking.danceLevel] || "N/A"} <br/>
          <strong>Dance Style</strong>: ${day.danceStyle.map((d) => DANCE_STYLE[d]).join(", ") || "N/A"} <br/>
          <strong>Hair at Night</strong>: ${booking.hairAtNight || "No"} <br/>
          <strong>Email</strong>: ${booking.email} <br/>
          <strong>Phone</strong>: ${booking.phoneNumber} <br/>
          <strong>Additional Notes</strong>: ${booking.additionalNotes || "None"} <br/>
        `;

        // Add an event for each schedule
        eventsList.push({
          id: `event_${booking._id}_${index}`,
          calendarId: schedule.assignedUser._id, // Use user _id or "unassigned" for unassigned schedules
          title: `${booking.firstName} ${booking.lastName}`,
          start: startDateTime,
          end: endDateTime,
          body: bodyText,
          category: "time",
        });
      });
    });

    // Add a special event for dance start time, if available
    if (booking.danceStartDate && booking.danceStartTime) {
      const danceStartDateTime = `${booking.danceStartDate}T${booking.danceStartTime}`;
      eventsList.push({
        id: `danceEvent_${booking._id}`,
        calendarId: "bookedDancers",
        title: `${booking.firstName} ${booking.lastName} - Dance Start`,
        start: danceStartDateTime,
        end: danceStartDateTime, // Single point in time
        body: `<strong>Dance Start</strong>: ${danceStartDateTime} <br/>`,
        category: "time",
      });
    }
  });

  calendarObj.createEvents(eventsList.sort((a, b) => a.calendarId.localeCompare(b.calendarId)));
}


function showDay() {
  if(calendarObj != null) {
    document.getElementById('calendar').style.height = "800px";
    calendarObj.changeView('day');
    calendarObj.setCalendarVisibility('events', true);
    displayRenderRange();
    buttonDayClass.value = 'btn btn-outline-secondary active';
    buttonWeekClass.value = 'btn btn-outline-secondary';
    buttonMonthClass.value = 'btn btn-outline-secondary';
  }
}

function showWeek() {

  if(calendarObj != null) {
    //earliestHour = 24;
    //latestHour = 0;
    //updateWeekTimeRange(0, 24);
    document.getElementById('calendar').style.height = "800px";
    calendarObj.changeView('week');
    calendarObj.setCalendarVisibility('events', true);
    displayRenderRange();
    buttonDayClass.value = 'btn btn-outline-secondary';
    buttonWeekClass.value = 'btn btn-outline-secondary active';
    buttonMonthClass.value = 'btn btn-outline-secondary';
  }
}

function showMonth() {

  if(calendarObj != null) {
    document.getElementById('calendar').style.height = "400px";
    calendarObj.changeView('month');
    calendarObj.setCalendarVisibility('events', false);
    displayRenderRange();
    buttonDayClass.value = 'btn btn-outline-secondary';
    buttonWeekClass.value = 'btn btn-outline-secondary';
    buttonMonthClass.value = 'btn btn-outline-secondary active';
  }
}

function showEventModal(title, body) {
  modalEventHeaderRef.value = "Booking for: " + title;
  modalEventBodyRef.value = body;
  // eslint-disable-next-line no-undef
  $('#customEventModal').modal('show'); // Trigger the modal
}
function calendarPrevious() {
  //earliestHour = 24;
  //latestHour = 0;
  //updateWeekTimeRange(0, 24);

  calendarObj.prev();
  displayRenderRange();
}

function calendarNext() {
  //earliestHour = 24;
  //latestHour = 0;
  //updateWeekTimeRange(0, 24);

  calendarObj.next();
  displayRenderRange();
}

function displayRenderRange() {
  var rangeStart = calendarObj.getDateRangeStart();
  var rangeEnd = calendarObj.getDateRangeEnd();

  dateRangeRef.value = getNavbarRange(rangeStart, rangeEnd, calendarObj.getViewName());
  calendarYearRef.value = moment(new Date(rangeStart.getTime() + (rangeEnd.getTime() - rangeStart.getTime()) / 2)).format('YYYY');
}

/*function getEventStartEndHours(event) {
  
  var start = event.start.toDate();
  var end = event.end.toDate();
  
  earliestHour = parseInt(moment(start).format("H")) < earliestHour ? parseInt(moment(start).format("H")) : earliestHour;
  latestHour = parseInt(moment(end).format("H")) > latestHour ? parseInt(moment(end).format("H")) : latestHour;
  
  updateWeekTimeRange(earliestHour, latestHour);
}

function updateWeekTimeRange(start, end) {

  if(start === 24 && end === 0) {
    calendarObj.setOptions({
      week: {
        eventView: false,
      },
    });
  }
  else {
    
    if(start > 0) start--;
    if(end < 24) end++;
  
    calendarObj.setOptions({
      week: {
        eventView: true,
        hourStart: start,
        hourEnd: end,
      },
    });
  }
}
*/
function getNavbarRange(tzStart, tzEnd, viewType) {
  var start = tzStart.toDate();
  var end = tzEnd.toDate();
  var middle;
  if (viewType === 'month') {
    middle = new Date(start.getTime() + (end.getTime() - start.getTime()) / 2);

    return moment(middle).format('MMMM');
  }
  if (viewType === 'day') {
    return moment(start).format('MMMM Do');
  }
  if (viewType === 'week') {
    return moment(start).format('MMM Do') + ' - ' + moment(end).format('MMM Do');
  }
  throw new Error('no view type');
}

onMounted(async () => {
  await loadUsers();
  await createCalendar();
  displayRenderRange();
});
</script>

<style>
.toastui-calendar-month-week-item {
  height: 60px !important;
}
.view-more-button {
  padding: 1px 3px;
  line-height: 0px;
}
.toastui-calendar-timegrid {
  height: 99%;
  min-height: 0px;
}
.btn-outline-primary:hover{
  color: #858796;
  background-color: transparent;
  border-color: #4e73df;
}
@media screen and (max-width: 786px) {
  .toastui-calendar-day-name__name{
    display: none;
  }
  .toastui-calendar-day-name__date {
    font-size: 12px;
  }
}

@media screen and (max-width: 1300px) {
  .view-more-button span.button-text{
    display: none;
  }
}

.toastui-calendar-day-view .toastui-calendar-panel:not(.toastui-calendar-time), 
.toastui-calendar-week-view .toastui-calendar-panel:not(.toastui-calendar-time) {
  overflow-y: hidden !important;
}
.toastui-calendar-see-more {
  height: 100%;
}
.toastui-calendar-popup-overlay {
  background-color: rgb(0 0 0 / 20%);
}
</style>